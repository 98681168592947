import React from "react";
import LongContent from "./LongContent";
function LegalTerms(props) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let state = params.get('state');
  console.log(params);
  return (
    <LongContent>
    </LongContent>
  );
}
export default LegalTerms;
